import React, { useContext } from 'react';
import styled from 'styled-components';
import {FavoritesWrapper} from '../global/FavoritesWrapper';
import {FavoriteItem} from '../global/Favorites';
import FavoritesContext from "../../context/favorites";

import useWindowDimensions from '../../hooks/useWindowDimensions';
import usePreloadFavoriteDragImages from '../../hooks/usePreloadFavoriteDragImages'


const FavoriteImgWrapper = styled.div`
  z-index:5000;
  user-select: none;   
  width: 100%; 
  height:100%;
`

const FavoritenDragOverlay = () => {
  const { favorites, setFavorites } = useContext(FavoritesContext);
  const dragImages = usePreloadFavoriteDragImages()

  const handleAddToFavorites = (data,index) => {
    const newFavData = JSON.parse(data)
    const temp = [...favorites] // spread old favorites
    temp[index] = {...newFavData}; // overwrite the right favorite
    setFavorites(temp)
  }

  return (
    <FavoritesWrapper zIndex={299} background={false}>
      {[...Array(12)].map((item,index) => (
        <FavoriteItem  key ={index}>
          <FavoriteImgWrapper
            draggable
            onDragStart={(e) => { 
              e.dataTransfer.setDragImage(dragImages[index], 32, 32);
              const favoriteData = JSON.stringify(favorites[index])
              e.dataTransfer.setData('Text', favoriteData); 
            }}
            onDragOver={(e)=> {
              e.preventDefault();
              e.stopPropagation();
            }}
            onDrop={(e)=> {
              e.preventDefault();
              e.stopPropagation();
              const data = e.dataTransfer.getData("Text");
              handleAddToFavorites(data,index)
            }}
          />
        </FavoriteItem>
       
      ))}
    </FavoritesWrapper>
  )
}

export default FavoritenDragOverlay 