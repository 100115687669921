import React, {useEffect,useRef,useState} from 'react';
import styled from 'styled-components';
import SwiperCore, { Navigation,Virtual, Autoplay   } from 'swiper';
import useInterval from '../../hooks/useInterval';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import arrowIcon from '../../images/icons/generieren/arrow.svg'
import {seedsEntryPointsData, seedsThumbs} from "../../data/dataUrls"
import timecodes from 'node-timecodes'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import useKioskMode from "../../hooks/useKioskMode"

SwiperCore.use([Navigation, Virtual, Autoplay]);

const VideoNavWrapper =  styled.div`
  position: absolute;
  height:85px;
  width:100%;
  z-index:20;
  background-color: white;
  display:flex;
  justify-content: space-between;
  align-items: center;
  bottom:${props => props.show ? props.kioskMode ? 'calc(50px + 60px)' : '50px' : '-100px' };
  transition: bottom 0.3s ease-in-out;
  user-select: none;
`
const FramesWrapper = styled.div`
  width:65%;
  display:flex;
  justify-content: space-around;
  align-items: center;
`

const PrevArrow = styled.img`
  position:absolute;
  height:20px;
  right:20px;
    &:hover{
    transform: scale(1.2);  
  }
  transition:all 0.05s ease-in-out;
`

const NextArrow = styled.img`
  position:absolute;
  left:20px;
  height:20px;
  transform: rotate(-180deg);
  &:hover{
    transform: rotate(-180deg) scale(1.2);
    transition:all 0.05s ease-in-out;
  }
`

const PatternThumb = styled.img`
  height:50px;
  width:50px;
  background-color: black;
  &:hover{
    border:2px solid white;
    z-index:999;
  }
`

const FrameControl = styled.div`
  width: 17.5%;
  height: 100%;
  z-index: 25;
  display:flex;
  justify-content: space-between;
  align-items: center;
  position:relative;
`

const PrevFrames = styled(FrameControl)`
  box-shadow: 10px 0px 20px #00000026;
  display:flex;
  justify-content: center;
  cursor:pointer;
`

const NextFrames = styled(FrameControl)`
  box-shadow: -10px 0px 20px #00000026;
  cursor:pointer;
`
const PrevText = styled.div`
  text-transform: uppercase;
  padding-right:20px;
`

const Marker = styled.div`
  z-index:10;
  position:absolute;
  border:2px solid black;
  height:60px;
  width:60px;
  left:50%;
  transform: translateX(-50%);
  pointer-events: none;
`

const Overlay = styled.div`
  z-index:10;
  position:absolute;
  height:60px;
  width:calc(50% - 30px);
  right:0%;
  user-select: none;
  pointer-events: none;
  background-color:white;
  opacity:0.75;
`

const VideoNav = ({playing, setPlaying,player, timeCode,setTimeCode,show}) => {
  const swiperRef = useRef(null)
  const scrubbingTimeline = useRef(false)
  const [entryPointsSeeds, setEntryPointsSeeds] = useState([])
  const updateSwiperInterval = 10
  const [slidesPerView, setSlidesPerView ] = useState(1)
  const { width } = useWindowDimensions();
  const [kioskMode, setKioskMode] = useKioskMode();
  useEffect(() => {
    /* Get initial number of slides */
    if(typeof(window) !== 'undefined'){   
      /* window width * swiper container width / slides width + slide gap  */ 
      setSlidesPerView((width * 0.65)/70)
    }

  },[])
 

  useEffect(() => {
    // get seeds data
    const fetchData = async () => {
      const resSeeds = await fetch(seedsEntryPointsData);
      const jsonSeeds = await resSeeds.json();
      if (jsonSeeds) {
        setEntryPointsSeeds(jsonSeeds)
      }
    };
    fetchData();
  }, []);



  
  useInterval(() => {
    // move swiper manually to create running timeline effect
    if(playing){
      const timeBetweenSlides = 8000
      const currentProgress = swiperRef.current?.swiper.progress
      const numberOfSlides= entryPointsSeeds.length
      const newprogress= currentProgress + 1/numberOfSlides * updateSwiperInterval/timeBetweenSlides 
      swiperRef.current?.swiper.setProgress(newprogress, 0)
    }
  }, updateSwiperInterval);
    
  useEffect(() => {
    // change slider position if player timecode changes
    // happens when user drags new starting image into player
    if(swiperRef != null && timeCode !=  null){
      const seconds = timecodes.toSeconds(timeCode)
      const index = seconds/8
      swiperRef.current?.swiper.slideTo(parseInt(index),0)
      if(index  % 1 !== 0){
        /* 
          If index modulo 1 is unequal to 0 it means that a non iteger index was passed. 
          Non generated patterns are in between(middle) of generated ones. To have the "timeline" in the right place 
          we must move in between two patterns.
         */
        const currentProgress = swiperRef.current?.swiper.progress
        const numberOfSlides= entryPointsSeeds.length
        const newprogress = currentProgress + 0.5/numberOfSlides
        swiperRef.current?.swiper.setProgress(newprogress, 0)
      }
    }
  }, [timeCode]);


  const handleNavArrowClick = (type) => {
   /*  const activeIndex = swiperRef.current?.swiper.activeIndex
    const newIndex = type === "next" ? activeIndex + 1 : activeIndex -1
    swiperRef.current?.swiper.slideTo(newIndex,1)
    player.current.seekTo(newIndex*8) */
  }


  return(
    <VideoNavWrapper show={show} kioskMode={kioskMode}>
      <PrevFrames onClick={() => handleNavArrowClick("prev")}>
        <PrevText> 
          <p style={{fontWeight:700,}}>Vorherige Muster</p>
          <p>Deiner Reise</p>
        </PrevText>
        <PrevArrow src={arrowIcon}></PrevArrow>
      </PrevFrames> 
      <FramesWrapper>
        <Marker></Marker>
        <Overlay/>
        <Swiper
          virtual={true}
          autoplay={{delay: 0,}}
          freeMode={true}
          speed={8000}
          centeredSlides={true}
          spaceBetween={20}
          slidesPerView={slidesPerView}
          onResize={(swiper) =>{setSlidesPerView(swiper.width/70)}} // updates slidesPerView i a responsive way
          onTouchEnd={(e) => {
            // update player when scrubbing "timeline"
            player.current.seekTo(e.activeIndex*8)
          }} 
          onTouchMove={(e) => {
            // disable click when 'scrubbing' so that the 'scrub' will not be overwritten by accidental clicks
            scrubbingTimeline.current = true
          }}
          style={{width:"100%",height:"50px", transitionTimingFunction: "linear"}}
          ref={swiperRef}
        >
          {entryPointsSeeds.map((entryPoint,index) => (
            <SwiperSlide key={entryPoint.mediaID} virtualIndex={index}>
              <PatternThumb
                src={`${seedsThumbs}${entryPoint.mediaID}`}
                onClick={() => {
                  // skip to thumb timecode only if the interaction is not a scrub 
                  if(!scrubbingTimeline.current){
                    setTimeCode(entryPoint.timecode)
                    
                    setPlaying(true);
                  } else {
                    scrubbingTimeline.current = false
                  }
                }}
                loading="lazy"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </FramesWrapper>
      <NextFrames  onClick={() => handleNavArrowClick("next")}>
        <NextArrow src={arrowIcon}></NextArrow>
      </NextFrames>
    </VideoNavWrapper>
  )
}

export default VideoNav

