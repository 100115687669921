import styled from "styled-components";
import React from "react"
import PageInfoText from "./PageInfoText";
import PageInfoTitle from "./PageInfoTitle";

const PageInfoWrapper = styled.div`
  position: relative;
  margin-left:4vw; 
  z-index:199;
  margin-top:20px;
  display:inline-flex;
  padding: 10px;
  align-items: center;
  font-family:'Avenir Next',sans-serif;
  background-color:white;
  position:${props => props.show === "true" && !props.screenshotMode  ? "relative"  : "absolute"};
  top:${props => !(props.show === "true" && !props.screenShotMode) && "-400px"};
  ${props => props.screenshotMode && "top:140px"};
  ${props => props.screenshotMode && "left:0px"};
  ${props => !props.screenshotMode && "transition:all 0.5s;"};
`

const PageInfo = ({title,text,show, screenshotMode}) => {

  return(
    <PageInfoWrapper show={show} screenshotMode={screenshotMode}>
      <div>
        <PageInfoTitle >{title}</PageInfoTitle >
        <PageInfoText >{text}</PageInfoText>
      </div>
    </PageInfoWrapper>
  )
}

export default PageInfo 