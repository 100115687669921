import { useContext, useState, useEffect } from 'react';
import {croppedPatternImageUrl} from "../data/dataUrls"
import FavoritesContext from "../context/favorites";

const usePreloadFavoriteDragImages = () => {
  // preload draging image to ensure it beeing there when in case of dragstart
  const { favorites} = useContext(FavoritesContext);
  const [dragImages, setDragImages ] = useState([])

  useEffect(() => {
    const draggingImages = favorites.map((item) => {
      if((typeof(item) !== 'undefined' ) && (item.path !== "undefined")) {
        const dragIcon = document.createElement('img');
        dragIcon.src = item.path.startsWith("data") ? item.path : `${croppedPatternImageUrl}64/${item.path}`;
        dragIcon.width = "64";
        dragIcon.style.border = "2px solid red";
        dragIcon.height = "64";
        return dragIcon;
      }
    })
    setDragImages(draggingImages);
  },[favorites])

  return dragImages

}

export default usePreloadFavoriteDragImages
