import React, {useState, useRef, useEffect, useContext } from 'react'
import styled, { keyframes, css } from 'styled-components'
import ReactPlayer from 'react-player/file'
import DownloadButton from '../components/global/DownloadButton'
import FavoritesContext from '../context/favorites'
import PageInfo from '../components/global/PageInfo'
import Seo from '../components/global/Seo'
import playIcon from '../images/icons/generieren/play.svg'
import pauseIcon from '../images/icons/generieren/pause.svg'
import FavoritenDragOverlay from '../components/generieren/FavoritesDragOverlay';
import VideoNav from '../components/generieren/VideoNav';
import DottedLine from '../components/global/DottedLine'
import timecodes from 'node-timecodes'
import useWindowDimensions from '../hooks/useWindowDimensions';
import usePrevious from '../hooks/usePrevious';

import captureVideoFrame from "capture-video-frame";
import PageSubtitle from '../components/global/PageSubtitle'

import loadingAnimation from '../images/erleben/mustertraum-loading.gif'

import {generateVideo, croppedPatternImageUrl} from "../data/dataUrls"
import {createFileName } from "../hooks/useScreenshot" 

/* import {croppedPatternImageUrl} from "../data/dataUrls"
import generateVideo from "../data/test.mp4" */

const PageWrapper = styled.div`
`

const PlayerWrapper = styled.div`
  min-height:100vh;
  min-width:100vw;
`

const PauseButton = styled.div`
  background-color:white;
  position:absolute;
  height:57px;
  width:57px;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index:20;
  display:flex;
  justify-content:center;
  align-items:center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index:30;
  user-select: none;
`

const PauseButtonIcon= styled.img``

const StyledPlayer = styled(ReactPlayer)`
  video{
    width:100vw;
    height:100vh; 
    object-fit:cover;
  }
`

const DragToStartFrame = styled.div`
  width:210px;
  height:210px;
  border:5px solid #D9D9D9;
  position: absolute;
  top:50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 25px #80808033;
  z-index:20;
  opacity:1;
`

const zoom = keyframes`
  from {
    width:200px;
    height:200px;
  }

  to {
    width:100vw;
    height:100vw
  }
`

const disappear = keyframes`
  from {
    opacity:1;
  }

  to {
    opacity:0;
  }
`

const StartImage = styled.img`
  position:absolute;
  left: 50%; 
  top:50%;
  transform: translate(-50%, -50%);
  width:200px;
  height:200px;
  opacity:1;
  pointer-events:none;
  object-fit:cover;
   ${props => props.animate && css`animation: ${zoom} 1s 1 linear 2s forwards,
                                             ${disappear} 1s 1 ease-out 4s forwards;`}


`
const PlayerSectionWrapper = styled.div`
  position:absolute;
  z-index:10;
  top:0;
  opacity:${props => props.show ? "1" : "0"};
`


const Tooltipp = styled.div`
  z-index:9999;
  position:absolute;
  right:13vw;
  top:250px;
  width:400px;
  >p{
    font-size:16px;
    font-weight: 600;
    padding: 10px 20px;
    background-color: #fff;
    text-transform: uppercase;
  }
`

// hide this somewhere "visible"
// see: https://www.kryogenix.org/code/browser/custom-drag-image.html
const DragImg = styled.img`
  height:50px;
  width:50px;
  position:absolute;
  bottom:70px;
  right:100px;
  z-index:1;
`


const Gif = styled.img`
  width:210px;
  height:210px;
  border:5px solid #D9D9D9;
  position: absolute;
  top:50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 25px #80808033;
  z-index:20;
  animation:  ${disappear} 1s 1 ease-out 1s forwards;                                         
`


const DownloadButtonWrapper = styled.div`
  z-index:499;
  position:absolute;
  right:100px;
  top:${props => props.show ? "60" : "-100"}px;
  transition:all 0.5s;
`

const Erleben = () => {
  // https://dev.to/protium/javascript-rendering-videos-with-html2canvas-3bk
  // https://www.google.com/search?q=Uncaught+DOMException%3A+Failed+to+execute+%27toDataURL%27+on+%27HTMLCanvasElement%27%3A+Tainted+canvases+may+not+be+exported.&rlz=1C1CHBD_deDE872DE872&oq=Uncaught+DOMException%3A+Failed+to+execute+%27toDataURL%27+on+%27HTMLCanvasElement%27%3A+Tainted+canvases+may+not+be+exported.&aqs=chrome..69i57.335j0j1&sourceid=chrome&ie=UTF-8
  const [playing, setPlaying] = useState(false)
  
  const [displayControls, setDisplayControls] = useState(true)

  const player = useRef()
  const timeoutRef = useRef()
  const mouseEventTimeoutRef = useRef()
  const dragImgRef = useRef()

  const [dragOutImage, setDragOutImage] = useState(false)

  const { favorites } = useContext(FavoritesContext)
  const { height, width } = useWindowDimensions();
  const [machineStarted, setMachineStarted] = useState(false)
  const [dragImage, setDragImage] = useState("")

  const [timeCode, setTimeCode] = useState(null)
  const [disablePlayerOnDrop, setDisablePlayerOnDrop] = useState(false)

  const prevDragImage = usePrevious(dragImage)
  const [playerMouseEventsEnabled, setPlayerMouseEventsEnabled] = useState(false)

  const takeScreenshot = () => {
    const playerRef = player.current.getInternalPlayer()
    const frame = captureVideoFrame(playerRef, "jpeg",0.6);
    download(frame.dataUri)
    return frame.dataUri
  }


  const getDragImage = () => {
    const playerRef = player.current.getInternalPlayer()
    const frame = captureVideoFrame(playerRef, "jpeg",0.6);
    return frame.dataUri
  }

  const download = (image) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName("jpg", "timbayern_calico_erleben");
    a.click();
  };

  useEffect(() => {
    if(timeCode != null){
      player.current.seekTo(
        timecodes.toSeconds(timeCode)
      );
    }
  }, [timeCode]);


  const handleStartFrameAnimationStart = () => {
      // reenable mouse events so mouse over will work for displaying ui
      // uses timeout instead of "handleStartFrameAnimationEnd" because animation will cancel sometimes
      clearTimeout(mouseEventTimeoutRef.current) 
      mouseEventTimeoutRef.current = setTimeout(() => {
        setPlayerMouseEventsEnabled(true)  // enable player mouse events (pause etc)
        setPlaying(true) // start player after animation is done 
        setMachineStarted(true) //hide some elements which should only be shown first time this doenst reset
      }, 1000)
  }

  const handlePointerMove = () => {
    if(playerMouseEventsEnabled) {
      clearTimeout(timeoutRef.current) 
      setDisplayControls(true)
      timeoutRef.current = setTimeout(() => {
        setDisplayControls(false)
      }, 2000)
    }
  }

  return (
    <>
    <Seo/>
    <DragImg ref={dragImgRef} src={dragOutImage}></DragImg>
    <PageSubtitle show="true">Lerne die Muster unserer Künstlichen Intelligenz kennen</PageSubtitle>
    <PageInfo 
      title={"Künstlich generierte Muster"} 
      text={"Eine Künstliche Intelligenz (KI) erschafft hier noch nie da gewesene Muster! Das Training der KI hat aus etwa 3.000 Originalmustern über 75.000 neue Muster entstehen lassen. Erlebe die spannenden und überraschenden Kreationen in einer inspirierenden Muster-Reise. Du kannst das fortlaufende Video jederzeit anhalten und die Muster zu Deinen Favoriten hinzufügen."}
      show={displayControls ? "true" : ""} //TODO: this doesnt work yet
    />
    {machineStarted && 
      <DownloadButtonWrapper show={displayControls}>
        <DownloadButton  onDownloadClick={takeScreenshot} text={"Dieses Muster herunterladen?"}/>
      </DownloadButtonWrapper>
    }
    <PageWrapper onPointerMove={handlePointerMove}>
      {displayControls && machineStarted && 
        <PauseButton onClick={() => setPlaying(!playing)}>
          <PauseButtonIcon src={playing ? pauseIcon : playIcon} />
        </PauseButton>
        }
      <FavoritenDragOverlay />
      {!machineStarted && favorites.length > 0 &&
        <DottedLine
          start={{x:width-105,y:height*0.4-170+20+22.5}} //height * top offset favorites + padding favorites + half size of favorite item
          end={{x:width/2,y:height/2}}
          offset={-250}
          tooltipp={"Ziehe das Muster ins Zentrum und starte Deine Muster-Reise"}
        />
      }
      {favorites.length === 0 && 
        <Tooltipp><p>Sammle zuerst Favoriten, dann kannst Du die Muster-Reise starten</p></Tooltipp>
      }
      {machineStarted && !playing &&
        <DottedLine
          end={{x:width-105,y:height*0.4-170+20+188.5}} //height * top offset favorites + padding favorites + half size of favorite item
          start={{x:width/4,y:height-200}}
          offset={-300}
          tooltipp={"Füge das Muster Deinen Favoriten hinzu"}
          textPositon={"start"}
        />
      }
      {!playing && 
        <>
          <DragToStartFrame  
            onDragOver={(e)=> {
              e.preventDefault();
              e.stopPropagation();
            }}
            onDrop={(e)=> {
              if(prevDragImage !== dragImage ){
                setPlayerMouseEventsEnabled(false) // disable mouse event so moving mouse wont rerender app (because of pause/play button) and interrupt animation
              }
              e.preventDefault();
              e.stopPropagation();
              const clicketFav= e.dataTransfer.getData("Text");
              const clicketFavData = JSON.parse(clicketFav)
              setTimeCode(clicketFavData.entryPointNAK.timecode)
              setDragImage(clicketFavData.path)
            }}    
          >
          {dragImage !== "" &&
            <>
              {prevDragImage !== dragImage && <Gif src={loadingAnimation}/> }
              <StartImage 
                animate={prevDragImage !== dragImage} //animated only if the image changed
                src={dragImage.startsWith("data")  ? dragImage : `${croppedPatternImageUrl}512/${dragImage}`}
                onAnimationStart={() => handleStartFrameAnimationStart()}
              />  
            </>
          }
          </DragToStartFrame>
         
        </>
      }
      <PlayerSectionWrapper
        show={machineStarted}
        draggable
        onDragOver={(e)=> {
          e.preventDefault();
          e.stopPropagation();
        }}
        onDrop={(e)=> {
        
          e.preventDefault();
          e.stopPropagation();
          if(!disablePlayerOnDrop){
            const clicketFav= e.dataTransfer.getData("Text");
            const clicketFavData = JSON.parse(clicketFav)
            setDragImage(clicketFavData.path)
            setTimeCode(clicketFavData.entryPointNAK.timecode)
            player.current.seekTo(
              timecodes.toSeconds(clicketFavData.entryPointNAK.timecode)
            );
            setPlaying(true);
          }
        }}
        onDragStart={(e) => { 
          setDisablePlayerOnDrop(true) // disable player as a drop target
          setPlaying(false);
          const dragImage = getDragImage()
          setDragOutImage(dragImage)
          const dragIcon = dragImgRef.current
         
          const data = {
            entryPointNAK: {timecode: timecodes.fromSeconds(parseInt(player.current.getCurrentTime()))},
            path: dragImage,
            sizeX: width,
            sizeY: height,
            generated:true,
          }
          e.dataTransfer.setDragImage(dragIcon, 0,0);
          e.dataTransfer.setData('Text', JSON.stringify(data));
  
        }}
        onDragEnd={(e) => { 
          // reset 
          setDisablePlayerOnDrop(false)
        }}
      >

        <PlayerWrapper
          onClick={() => {
            if(playerMouseEventsEnabled) {
              setPlaying(!playing);
            }
          }}
        >
          <StyledPlayer
            ref={player}
            url={generateVideo} 
            autoPlay={true} 
            controls={false}
            width={"100vw"}
            height={"100vh"}
            playing={playing}
            playbackRate={1}
          />
        </PlayerWrapper>
        <VideoNav
          playing={playing}
          setPlaying={setPlaying}
          player={player}
          timeCode={timeCode}
          setTimeCode={setTimeCode}
          show={displayControls}
        />
      </PlayerSectionWrapper>
      
    </PageWrapper>
    </>
  );



}


export default Erleben